import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import logo from '../../assets/images/logo.png';
import './style.css';


const HomeHeader = () => {
  return (
    <Navbar fixed="top" expand="lg" className="navbar-home"   >
      <Navbar.Brand href="/">
        <img
          src={logo}
          alt="Logo"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto header-nav">
          <Nav.Link href="/" className='menu-option'>Home</Nav.Link>
          <Nav.Link href="/Dashboard" className='menu-option'>Doctor Dashboard</Nav.Link>
          <Nav.Link href="#" className='menu-option'>Patient Dashboard</Nav.Link>
          <Nav.Link href="#" className='menu-option'>Calendar</Nav.Link>
        </Nav>
        <Button variant="primary" href='/DoctorLogin'  style={{backgroundColor:'#15558d',borderColor:'#15558d'}}>Login / Signup</Button>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HomeHeader;
