import { useState, useEffect } from "react";
import { Row, Container, Col, Form, Button, Table } from 'react-bootstrap';
import React from "react";
import Sidebar from '../SideBar';
import axios from '../../../api/axios';
import '../../../App.css';
import Header from '../Header';
import Footer from '../../Footer';
import { FaPlus, FaMinus } from 'react-icons/fa';
import "./style.css";
import {getToken} from '../../../utils/auth';
import '../../../utils/doctor_info';
import Doctor from '../../../utils/doctor_info';


const AddTimeSlots = () => {
 
  const [selectedDateForCol, setSelectedDateForCol] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedDateDuration, setSelectedDateDuration] = useState('');
  const [date, setDate] = useState('');
  const [duration, setDuration] = useState('');
  const [hasSelectedDuration, setHasSelectedDuration] = useState(false);
  const [timeTemplates, setTimeTemplates] = useState([]);
  const [showPlusContent, setShowPlusContent] = useState(false);
  const [timeSlotDuration, setTimeSlotDuration] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [dateToBeAdded, setDateToBeAdded] = useState('1970-01-01');
  const token = getToken;

  const doc = new Doctor();

  const doctorId = doc.id;

  const fetchTimeTemplates = async () => {
    console.log(doctorId)
    try {
      const response = await axios.get(`/api/time_templates/doctor/${doctorId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      let temps = response.data.timeTemplates;
      setTimeTemplates(temps);
      // console.log(temps)

    } catch (error) {
      console.error('Error fetching time templates:', error);
    }
  };

  useEffect(() => {
    fetchTimeTemplates();
  }, []);

  const handleDateChange = (e) => {
    try {
      const selectedDate = new Date(e.target.value);
      const dayOfWeek = selectedDate.getDay();

      const matchingTemplate = timeTemplates.find(template => template.day_of_week === dayOfWeek);
      // console.log(dayOfWeek)
      console.log(matchingTemplate._id);
      setDateToBeAdded(selectedDate);
      if (matchingTemplate) {
        setTemplateId(matchingTemplate._id)
        // setSelectedDateDuration(templateId); 

        // console.log(templateId);
      } else {
        // Handle the case where no matching template is found
        console.log("No matching template found for this day of the week.");
        // You might want to clear the templateId or show an error message to the user
      }

      setDateToBeAdded(selectedDate);
    } catch (error) {
      // Handle the error gracefully
      alert("No template ID available for the selected date. Please choose another Date.");

      // You might want to clear the templateId or show an error message to the user
    }
  };

  const generateTimeSlots = async () => {

    const requestData = {

    };

    try {
      const response = await axios.post(
        `/api/doctor_time_slots/doctor/${doctorId}/${templateId}/create/day/${selectedDateDuration}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

    } catch (error) {

      console.log(`/api/doctor_time_slots/doctor/${doctorId}/${templateId}/create/day/${selectedDateDuration}`)
    }
  };




 


  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>

                  <li class="breadcrumb-item active" aria-current="page">Available Timings</li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title">Available Timings </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3">
              <Sidebar />
            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <Container fluid>
                <Row className="mt-4">
                  <h1 style={{ color: "#05054c" }}>Generate Timings</h1>
                </Row>
                <Row className="justify-content-center">
                  <div className="plus-button">
                    <button
                      className="plus-icon-button"
                      onClick={() => setShowPlusContent(!showPlusContent)}
                    >
                      {showPlusContent ? <FaMinus /> : <FaPlus />}
                    </button>
                  </div>
                </Row>
                {showPlusContent && (
                  <Row className="plus-content">
                    <Col xs={12}>
                      <br />
                      <div className="duration-selection">
                        <label>Select duration: </label>
                        <select
                          value={timeSlotDuration}
                          onChange={(e) => {
                            setTimeSlotDuration(e.target.value);
                            setHasSelectedDuration(true);
                          }}
                        >
                          <option value="day"></option>
                          <option value="1 day">1 day</option>
                          <option value="1 week">1 week</option>
                          <option value="1 month">1 month</option>
                          <option value="3 months">3 months</option>
                          <option value="6 months">6 months</option>
                        </select>
                      </div>
                      {hasSelectedDuration && timeSlotDuration !== "0 day" && (
                        <div className="date-selection">
                          <label>Select date:</label>
                          <input
                            type="date"
                            value={selectedDateDuration}
                            onChange={(e) => {
                              setSelectedDateDuration(e.target.value);
                              handleDateChange(e);
                            }}
                          />
                        </div>
                      )}
                      <button class="btn btn-primary" style={{ backgroundColor: "#15558d", borderColor: "#15558d" }} onClick={generateTimeSlots}>
                        Generate Time Slots
                      </button>
                      
                    </Col>
                  </Row>
                )}
                
                {/* <Row>
                  <Col xs={12}>
                    <div className="timing-container ">
                      <Table bordered hover responsive="sm" className="time-table">
                        <tbody>
                          {generateHourRows().map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              {row.map((hour, index) => (
                                <td key={index}>
                                  <Button
                                    className={`time-button ${selectedHour === hour ? "active" : ""
                                      }`}
                                    variant="outline-primary"
                                    onClick={() => handleHourSelect(hour)}
                                    active={selectedHour === hour}
                                    style={{
                                      backgroundColor: selectedHour === hour ? "#1a1aff" : "#e2e2e2",
                                      color: selectedHour === hour ? "#fff" : "#000",
                                      width: "100%",
                                      border: "none",
                                    }}
                                  >
                                    {hour}
                                  </Button>
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
                 */}
              </Container>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </div>
  );
};

export default AddTimeSlots;