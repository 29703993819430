import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Sidebar from '../SideBar';
import '../../../App.css';
import Header from '../Header';
import Footer from '../../Footer';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      alert('New password and confirmation password do not match!');
    } else {
      // TODO: implement your password change logic here
      alert('Password successfully changed!');
    }
  };

  return (
    <div>

      <Header />


      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>

                  <li class="breadcrumb-item active" aria-current="page">Change Password</li>
                </ol>
              </nav>

              <h2 class="breadcrumb-title">Change Password Page</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-fluid">

          <div className="row">

            <div className="col-md-5 col-lg-4 col-xl-3 ">

              <Sidebar />

            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
            <Row> <h6 style={{color:'red'}}>This Page is Static. It doesn't fetch data from Backend</h6></Row>
                    
              <Container>
                <Row className="justify-content-md-right">
                  <Col xs={12} md={7}>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="formCurrentPassword">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter current password"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formNewPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter new password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formConfirmNewPassword">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirm new password"
                          value={confirmNewPassword}
                          onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                      </Form.Group>

                      <Button variant="primary" type="submit" style={{backgroundColor:'#15558d'}}>
                        Save Changes
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Container>

            </div>

          </div>

        </div>
      </div>

      <Footer />

    </div>
  );
};

export default ChangePassword;