import React from 'react'
import './style.css';
import {BsCheckCircleFill} from "react-icons/bs";
import './ViewAppointment'
import '../../../App.css';
import Header from '../Header';
import Footer from '../../Footer';
import { useNavigate } from 'react-router-dom';


const BookingSuccess = () => {
    const navigate = useNavigate();

  return (
    <div>
            {/* Header */}
            <Header />
            {/* Header */}

            {/* Breadcrumb */}
            <div class="breadcrumb-bar">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-12 col-12">
                            <nav aria-label="breadcrumb" class="page-breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                                    {/* You should change the breadcrumb Title */}
                                    <li class="breadcrumb-item active" aria-current="page">Booking Success</li>
                                </ol>
                            </nav>
                            {/* You should change the breadcrumb Title */}
                            <h2 class="breadcrumb-title">Booking Confirmed</h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumb */}
            {/* Body Content */}
           
                

  


<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }} >
  <form class="success"  style={{ margin: '0 auto', textAlign: 'center' , padding:'2rem' }}>
 <BsCheckCircleFill class="done"  style={{ fontSize: '30px' }} /> <br/> <br/>
    <a style={{ fontWeight: 'bold' }}>Appoinment booked Succefully!</a> <br/>
    <a style={{ fontSize: '12px'}} >Appoinment booked with</a><a style={{ fontWeight: 'bold', fontSize: '12px', fontStyle:'Verdana, Arial, Helvetica, sans-serif' }} >  Dr.John Doe</a> <br/>
    <a style={{ fontSize: '12px'}} >on</a><a style={{ fontWeight: 'bold', fontSize: '12px' }}> 12 Nov 2023 5:00PM to 6:00PM</a> <br/> <br/>

     <button className="btn btn-primary " style={{backgroundColor:'#15558d'}} onClick={() => navigate("/ViewAppointment")}>
          View Appointment</button>
  </form>
 



</div>

{/* Footer */}
<Footer />
{/* Footer */}
</div>
)
}
  


export default BookingSuccess;