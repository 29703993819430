import React , {useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
export default function GeneralInfo(props) {

  const [title, setTitle] = React.useState('Dr.');
   const [gender, setGender] = React.useState('male');
  const [firstName, setFirstName] = React.useState('');
  const [middleName, setMiddleName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [address, setaddress] = React.useState('');

  function  handleChange (event){
    event.preventDefault();
    // setTitle()
    if(event.target.id =='firstName'){
   setFirstName(event.target.value);
    }
   else if(event.target.id =='middleName'){
      setMiddleName(event.target.value)
    }
    else if(event.target.id =='lastName'){
      setLastName(event.target.value)
    }
    else if(event.target.id =='address'){
      setaddress(event.target.value)
    }
    else if(event.target.id =='title'){
      setTitle(event.target.value)
    }
    else  if(event.target.id =='gender'){
      setGender(event.target.value)
    }
    props.sendPersonalInfo({
      title: title,
      gender: gender,
      firstname: firstName,
      middlename: middleName,
      lastname: lastName,
      location: address,
    })
}


  return (
    <React.Fragment>
    
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Select
          required
            labelId="demo-simple-select-label"
            id="title"
            value={title}
            label="Title"
            onChange={ handleChange}
          > 
          
          <MenuItem value={'Dr.'}>Dr.</MenuItem>
            <MenuItem value={'Med.'}>Med.</MenuItem>
            <MenuItem value={'Prof.'}>Prof.</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
          required
            labelId="gender"
            id="gender"
            value={gender}
            label="Gender"
            onChange={handleChange}
            // onChange={changeGender}
          > <MenuItem value={'male'}>Male</MenuItem>
            <MenuItem value={'female'}>Female</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            onChange={handleChange}
            // onChange={e => setFirstName(e.target.value)}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            // onChange={e => setMiddleName(e.target.value)}
            onChange={handleChange}
            id="middleName"
            name="middleName"
            label="Middle name"
            fullWidth
            autoComplete="middle-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            onChange={handleChange}
            // onChange={e => setLastName(e.target.value)}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            required
            onChange={handleChange}
            // onChange={e => setaddress(e.target.value)}
            id="address"
            name="clinicAddress"
            label="Clinic Address"
            fullWidth
            variant="standard"
          /></Grid>


      </Grid>

    </React.Fragment>
  );
}