
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Image } from 'react-bootstrap';
import './style.css';
import { FaMapMarker, FaMapMarkerAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import '../../../App.css';
import Header from '../Header';

import Footer from '../../Footer';

import './BookingSuccess';


const Checkout = () => {
    // State for booking data
    const [bookingData, setBookingData] = useState({
        name: 'Dr. Darren Elder',
        date: '16 November 2023',
        time: '10:00 AM',
        service: 'Massage',
        location: 'lebanon',
        reviewStars: 4,
        imageUrl: require('../../../../src/assets/images/patients/patient1.jpg') // Place your image path here
    });

    // State for personal info
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', { firstName, lastName, email, phoneNumber });
    };

    // Function to render stars for review
    const renderStars = (count) => {
        return [...Array(count)].map((_, idx) => (
            <span key={idx} className="star">★</span>
        ));
    };
    const navigate = useNavigate();
    return (
        <div>
            {/* Header */}
            <Header />
            {/* Header */}

            {/* Breadcrumb */}
            <div class="breadcrumb-bar">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-12 col-12">
                            <nav aria-label="breadcrumb" class="page-breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Home</a></li>

                                    <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
                                </ol>
                            </nav>
                            <h2 class="breadcrumb-title">Checkout Page</h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumb */}
            {/* Body Content */}
            <div class="content">
                <div class="container-fluid">

                    <div className="row">

                        <div className="col-12">
                            <Container className="checkoutContainer">
                                <Row>
                                    <Col lg={8}>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title>Personal Information</Card.Title>
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>First Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={firstName}
                                                                    onChange={(e) => setFirstName(e.target.value)}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Last Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={lastName}
                                                                    onChange={(e) => setLastName(e.target.value)}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Email</Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Phone Number</Form.Label>
                                                                <Form.Control
                                                                    type="tel"
                                                                    value={phoneNumber}
                                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                    
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={4}>
                                        <h3>Booking Summary</h3>
                                        <hr className="thinBorder" />
                                        <div className="mb-3  d-flex " >
                                            {/* style="column-gap:0.5rem*/}
                                            <div>
                                                <Image src={bookingData.imageUrl} rounded width={100} />
                                            </div>
                                            <div className='infostarloc'>
                                                <div >{renderStars(bookingData.reviewStars)}
                                                    <p className='mr-left'>{bookingData.name}</p>
                                                    <p><FaMapMarkerAlt />  {bookingData.location}</p> </div>
                                            </div>

                                        </div>
                                        <p >Date: {bookingData.date}</p>
                                        <p >Time: {bookingData.time}</p>
                                        <Button className="btn btn-primary mt-0" style={{backgroundColor:'#15558d'}} onClick={() => navigate("/BookingSuccess")}>
                                            Confirm Booking</Button>

                                    </Col>
                                </Row>
                            </Container>









                        </div>

                    </div>

                </div>
            </div>
            {/* Footer */}
            <Footer />
            {/* Footer */}
        </div>
    )
}


export default Checkout;