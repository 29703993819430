import React, { useState, useEffect } from 'react';
import { FaStar, FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import { Container, Row, Col, Button, Table,Form  } from 'react-bootstrap';
import Sidebar from '../SideBar';
// import '../../../App.css';
import Header from '../Header';
import Footer from '../../Footer';
import axios from '../../../api/axios'; 
import {getToken} from '../../../utils/auth';
import './style.css';
import '../../../utils/doctor_info';
import Doctor from '../../../utils/doctor_info';
import { useNavigate } from 'react-router-dom';


const AvailableTimings = () => {
  const [doctorSchedule, setDoctorSchedule] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const token = getToken;

  const doc = new Doctor();

  const doctorId = doc.id;
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        

        //64dbb1ad7c3480aef8ff9a33
         const response = await axios.get(`/api/doctor_time_slots/doctor/${doctorId}/day/${selectedDate}`,
          {headers: {
            Authorization: `Bearer ${token}`
          
      }});

        setDoctorSchedule(response.data.timeSlots);
        // console.log(response.data.timeSlots)
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchData();
  }, [token, doctorId]);
 
  const start_times = [];
  const generateHourRows = () => {
    const rows = [];
    fetchTimes();
    for (let i = 0; i < start_times.length; i += 5) {
      const status = bookingStatus.slice(i, i+5)
      const chunk = start_times.slice(i, i + 5);
      let slots = { chunk, status};
      rows.push(slots);
      
    }
    return rows;
  };
const bookingStatus =[];
  const fetchTimes = () => {
    for (const item of doctorSchedule) {
      start_times.push(item.start_time);
      bookingStatus.push(item.is_booked)
      
    }

  };
  const handleDateChange = (e) => {
    
    setSelectedDate(e.target.value);
    
  };
  const showResults = async () => {
  
   
      try {
        

        //64dbb1ad7c3480aef8ff9a33
         const response = await axios.get(`/api/doctor_time_slots/doctor/${doctorId}/day/${selectedDate}`,
          {headers: {
            Authorization: `Bearer ${token}`
          
      }});

        setDoctorSchedule(response.data.timeSlots);
        
        // console.log(response.data.timeSlots)
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }


   
  }
  return (
      <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
          <div class="container-fluid">
              <div class="row align-items-center">
                  <div class="col-md-12 col-12">
                      <nav aria-label="breadcrumb" class="page-breadcrumb">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><a href="/">Home</a></li>
                              {/* You should change the breadcrumb Title */}
                              <li class="breadcrumb-item active" aria-current="page">Available Timings</li>
                          </ol>
                      </nav>
                    
                      <h2 class="breadcrumb-title">Available Timings</h2>
                  </div>
              </div>
          </div>
      </div>
    
      <div class="content">
          <div class="container-fluid">

              <div className="row">
           
                  <div className="col-md-5 col-lg-4 col-xl-3 ">

                      <Sidebar />

                  </div>
                
      
               
                  <div className="col-md-7 col-lg-8 col-xl-9">
                  <Row className="mt-4">
                  <h1 style={{ color: "#05054c" }}>Available Timings</h1>
                </Row>
                <br></br>
                  <button
                      className="btn btn-primary" style={{ backgroundColor: "#15558d", borderColor: "#15558d" }}
                      onClick={() => navigate("/AddTimeSlots")}
                    >
                      Generate Time Slots
                    </button>
                    <br></br>
                     <Form.Group><Row >
                  
                   
                  <Col xs={4} md={4} ><br></br>
                      <Form.Control
                        className="form-control"
                        type="date"
                        
                        onChange={handleDateChange}
                      />
                      </Col>
                      
                      <Col xs={4} md={4}><br></br> <Button
                        type="submit"
                        style={{ backgroundColor: "#15558d", borderColor: "#15558d" }} onClick={showResults}
                      >
                        Show Results
                      </Button>
                      </Col>
                    
                 
                </Row></Form.Group>
                  <Row >
                  <Col xl={12}>
                    <div className="timing-container ">
                      <Table bordered hover responsive="sm" className="time-table " style={{backgroundColor: "#fff" }}>
                        <tbody>
                          {generateHourRows().map((row, rowIndex) =>
                          
                          // {row.map((chunk, rowIndex) => ( console.log(chunk),
                            <tr key={rowIndex}>
                              {row.chunk.map((hour, index) => ( console.log(row.status),
                                <td key={index}>
                                  <Button
                                    className={'time-button '}
                                    variant="outline-primary"
                                    
                                    style={{
                                      backgroundColor: row.status[index] ? "#ff6666" : "#99cc5c",
                                      color: "#000" ,
                                      width: "100%",
                                      border: "none",
                                    }}
                                  >
                                    {hour}
                                  </Button>
                                </td>
                              ))}
                            </tr>
                           
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
        
        </div>

</div>

</div>
</div>
{/* Footer */}
<Footer />
{/* Footer */}
</div>
      )
    }
    
  
export default AvailableTimings;