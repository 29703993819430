import React from 'react'

import '../App.css';
import { Navbar, Nav, Button } from 'react-bootstrap';
import logo from '../assets/images/logo.png';
import Footer from './Footer';

const NotFound = () => {
    return (
        <div>
            {/* Header */}
            <Navbar fixed="top" expand="lg" className=""  style={{backgroundColor:'#fff'}} >
      <Navbar.Brand href="/">
        <img
          src={logo}
          alt="Logo"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto header-nav">
          <Nav.Link href="/" className='menu-option'>Home</Nav.Link>
          <Nav.Link href="/Dashboard" className='menu-option'>Doctor Dashboard</Nav.Link>
          <Nav.Link href="#" className='menu-option'>Patient Dashboard</Nav.Link>
          <Nav.Link href="#" className='menu-option'>Calendar</Nav.Link>
        </Nav>
        <Button variant="primary" href='/DoctorLogin'  style={{backgroundColor:'#15558d',borderColor:'#15558d'}}>Login / Signup</Button>
      </Navbar.Collapse>
    </Navbar>
            {/* Header */}

            {/* Breadcrumb */}
            <div class="breadcrumb-bar">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-12 col-12">
                            <nav aria-label="breadcrumb" class="page-breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                                    {/* You should change the breadcrumb Title */}
                                    <li class="breadcrumb-item active" aria-current="page">404</li>
                                </ol>
                            </nav>
                            {/* You should change the breadcrumb Title */}
                            <h2 class="breadcrumb-title">Page Not Found</h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumb */}
            {/* Body Content */}
            <div class="content">
                <div class="container-fluid">

                    <div className="row">
                       

                        {/* Page Content Should be Added here inside this div */}
                        <div className="col-md-12 col-lg-12 col-xl-12 d-flex align-items-center justify-content-center">
  <img src={require('../assets/images/404.png')} width={'40%'} height={'30%'} alt="404 Error" />
</div>

                    </div>

                </div>
            </div>
            {/* Footer */}
            <Footer />
            {/* Footer */}
        </div>
    )
}

export default NotFound;
