import React, { useState, useEffect } from 'react';
import { FaCheck, FaTimes, FaEye } from "react-icons/fa";
import { Button, Row, Table, Tabs, Tab } from 'react-bootstrap';
import Sidebar from '../SideBar';
import Header from '../Header';
import Footer from '../../Footer';
import { useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import { getToken } from '../../../utils/auth';
import '../../../utils/doctor_info';
import Icon01 from '../../../assets/images/icon-01.png';
import Icon02 from '../../../assets/images/icon-02.png';
import Icon03 from '../../../assets/images/icon-03.png';
import Doctor from '../../../utils/doctor_info';
import { FaMapMarkerAlt, FaPhoneAlt, FaUser, FaUserAlt, FaCalendar, FaClock } from 'react-icons/fa';

const token = getToken;
const doc = new Doctor();

const doctorId = doc.id;


const Dashboard = () => {
  const [previousStatus, setPreviousStatus] = useState({});

  const [attendanceMap, setAttendanceMap] = useState({});
  const [lastAttendedAppointmentId, setLastAttendedAppointmentId] = useState(null);

  const [key, setKey] = useState(1);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [todayAppointments, setTodayAppointments] = useState([]);
  const navigate = useNavigate();
  const handleCancel = async (appointmentId) => {
    // Show a confirmation dialog
    const isConfirmed = window.confirm("Are you sure you want to cancel this appointment?");

    // If the user confirms the cancellation, proceed with the cancellation logic
    if (isConfirmed) {
      try {
        const response = await axios.patch(`/api/doctors/${doctorId}/appointments/${appointmentId}/cancel`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.data.success) {
          // Update the upcomingAppointments state
          const updatedUpcomingAppointments = upcomingAppointments.map(appt =>
            appt._id === appointmentId ? { ...appt, status: 'canceled' } : appt
          );
          setUpcomingAppointments(updatedUpcomingAppointments);

          // New: Update the todayAppointments state as well
          const updatedTodayAppointments = todayAppointments.map(appt =>
            appt._id === appointmentId ? { ...appt, status: 'canceled' } : appt
          );
          setTodayAppointments(updatedTodayAppointments);
        } else {
          console.error("Error canceling the appointment:", response.data);
        }
      } catch (error) {
        console.error('Error canceling the appointment:', error);
      }
    }
  };


  const handleView = (appointmentId) => {
    console.log("Viewing appointment with ID:", appointmentId);
    // Add your logic to view the appointment details here
  }; 
  
  const handleAttendance = async (appointmentId) => {
    console.log("handleAttendance called for:", appointmentId);

    // Current status
    const currentStatus = attendanceMap[appointmentId] || "booked";

    // Store the previous status before making any changes
    setPreviousStatus({ appointmentId, status: currentStatus });

    const targetStatus = currentStatus === "booked" ? "attended" : "booked";

    try {
      const response = await axios.patch(`/api/doctors/${doctorId}/appointments/${appointmentId}/attend`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.success) {
        // Extract the attendedAppointment from the response
        const attendedAppointment = response.data.attendedAppointment;

        // Use the attendedAppointment status (attendedAppointment.status) to update the component state
        setAttendanceMap(prev => ({
          ...prev,
          [appointmentId]: attendedAppointment.status
        }));

        // Also, update the todayAppointments to reflect the change
        const updatedTodayAppointments = todayAppointments.map(appt =>
          appt._id === appointmentId ? { ...appt, status: attendedAppointment.status } : appt
        );
        setTodayAppointments(updatedTodayAppointments);

        // Save the last appointment that was marked as attended or reverted
        setLastAttendedAppointmentId(appointmentId);
      } else {
        console.error("Error recording attendance:", response.data);
      }
    } catch (error) {
      console.error("Error recording attendance:", error);
    }
  };
  const handleAbsence = async (appointmentId) => {

    // Current status
    const currentStatus = attendanceMap[appointmentId] || "booked";

    // Store the previous status before making any changes
    setPreviousStatus({ appointmentId, status: currentStatus });

    try {
      //This is the api for setting status to Absent ( it means booked not attended)
      const response = await axios.patch(`/api/doctors/${doctorId}/appointments/${appointmentId}/booked`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.success) {
        // Extract the attendedAppointment from the response
        const absentAppointment = response.data.updatedAppointment;

        // Use the attendedAppointment status (attendedAppointment.status) to update the component state
        setAttendanceMap(prev => ({
          ...prev,
          [appointmentId]: absentAppointment.status
        }));

        // Also, update the todayAppointments to reflect the change
        const updatedTodayAppointments = todayAppointments.map(appt =>
          appt._id === appointmentId ? { ...appt, status: absentAppointment.status } : appt
        );
        setTodayAppointments(updatedTodayAppointments);

        // Save the last appointment that was marked as attended or reverted
        setLastAttendedAppointmentId(appointmentId);
      } else {
        console.error("Error recording attendance:", response.data);
      }
    } catch (error) {
      console.error("Error recording attendance:", error);
    }
  };


  useEffect(() => {
    const handleUndo = (e) => {
      if (e.ctrlKey && e.key === 'z' && previousStatus.appointmentId) {
        setAttendanceMap(prev => ({
          ...prev,
          [previousStatus.appointmentId]: previousStatus.status
        }));
        setPreviousStatus({}); // Clear previous status after undo
      }
    };

    window.addEventListener('keydown', handleUndo);
    return () => window.removeEventListener('keydown', handleUndo);
  }, [lastAttendedAppointmentId]);


  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        // Fetching upcoming appointments
        const upcomingResponse = await axios.get(`/api/doctors/${doctorId}/upcoming/appointments`, {

          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (upcomingResponse.data.success && Array.isArray(upcomingResponse.data.appointments)) {
          setUpcomingAppointments(upcomingResponse.data.appointments);

        } else {
          console.error("API didn't return the expected structure for upcoming appointments:", upcomingResponse.data);
          setUpcomingAppointments([]);
        }

        // Fetching today's appointments
        const todayResponse = await axios.get(`/api/doctors/${doctorId}/today/appointments`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (todayResponse.data.success && Array.isArray(todayResponse.data.appointments)) {
          setTodayAppointments(todayResponse.data.appointments);
        } else {
          console.error("API didn't return the expected structure for today's appointments:", todayResponse.data);
          setTodayAppointments([]);
        }
      } catch (error) {
        console.error('Error fetching the appointments:', error);
        setUpcomingAppointments([]);
        setTodayAppointments([]);
      }
    };
    fetchAppointments();
  }, [token, doctorId]);

  const handleSelect = (key) => {
    setKey(key);
  };

  return (
    <div>
      <Header />
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
                </ol>
              </nav>
              <h2 class="breadcrumb-title">Doctor Dashboard</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container-fluid">



          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 ">

              <Sidebar />

            </div>
            <div className="col-md-7 col-lg-8 col-xl-9">
              <Row>
                <div className="col-md-12">
                  <div className="card dash-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget dct-border-rht">

                            <div>
                              <img
                                src={Icon01}
                                className="img-fluid"
                                alt="Patient"
                              />
                            </div>

                            <div className="dash-widget-info">
                              <h6>Total Patients</h6>
                              <h3>15</h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget dct-border-rht">
                            <div>
                              <img
                                src={Icon02}
                                className="img-fluid"
                                alt="Patient"
                              />
                            </div>
                            <div className="dash-widget-info">
                              <h6>Today's Patient</h6>
                              <h3>10</h3>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget">
                            <div>
                              <img
                                src={Icon03}
                                className="img-fluid"
                                alt="Patient"
                              />
                            </div>
                            <div className="dash-widget-info">
                              <h6>Appoinments</h6>
                              <h3>55</h3>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
              <br />
              <Row>
                <div class="col-md-12">
                  <h4 class="mb-4">Patient Appoinments</h4>
                  <div class="appointment-tab">
                    <Tabs
                      className="tab-view"
                      activeKey={key}
                      onSelect={handleSelect}
                      id="controlled-tab-example"
                    >
                      <Tab className="nav-item" eventKey={1} title="Today">
                        <div className='cardtitle'>
                          <div className="table-title">
                            <h3>Today's Appointments</h3>
                          </div>
                        </div>
                        <div className="px-4 py-2 overflow-auto">
                          <Table className="tab1" striped bordered hover>
                            <thead className="head">
                              <tr>
                                <th>Patient Name</th>
                                <th>Phone number</th>
                                <th>Governorate</th>
                                <th>Appt Date</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {todayAppointments.map(appointment => (
                                <tr key={appointment._id}>
                                  <td><FaUserAlt style={{ color: '#15558d', marginRight: '10px' }} /> {appointment.patient_name} {appointment.patient_family}</td>
                                  <td> {appointment.patient_phone}</td>
                                  <td><FaMapMarkerAlt style={{ color: '#D32323', marginRight: '10px' }} /> {appointment.patient_governorate}</td>
                                  <td>{new Date(appointment.appointment_date).toLocaleDateString()}</td>
                                  <td>{appointment.start_hour}</td>
                                  <td>{appointment.end_hour}</td>
                                  <td>
                                    <p className='{appointment.status}'>
                                      {/* Make appointment status start by capital letter*/}
                                      {appointment.status[0].toUpperCase() + appointment.status.slice(1)}
                                    </p>
                                  </td>
                                  <td >


                                    {/* Logic for Attend/Absent and Cancel buttons based on appointment status */}
                                    {appointment.status === "booked" && (
                                      <> <Row className='d-flex justify-content-center' >
                                        <Button style={{backgroundColor:'#99cc5c', borderColor:'#99cc5c', marginRight: 10}} onClick={() => handleAttendance(appointment._id)}>Attend</Button>

                                        <Button variant="danger" onClick={() => handleCancel(appointment._id)}>
                                          Cancel
                                        </Button>
                                      </Row>
                                      </>
                                    )}
                                    {appointment.status === "attended" && (
                                    <Row className='d-flex justify-content-center'>  <Button style={{backgroundColor:'  #e5a534 ', borderColor:' #e5a534 ',width:'100%', marginRight:10}} onClick={() => handleAbsence(appointment._id)}>Set Absence</Button>
                                    </Row> )}
                                 
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Tab>
                      <Tab className="nav-item" eventKey={2} title="Upcoming">
                        <div className='cardtitle'>
                          <div className="table-title">
                            <h3>Upcoming Appointments</h3>
                          </div>
                        </div>
                        <div className="px-4 py-2 overflow-auto">

                          <Table className='tab1' striped bordered hover >
                            <thead className="head">
                              <tr>
                                <th >Patient Name</th>

                                <th> Phone number </th>
                                <th>Governorate</th>
                                <th>Appt Date</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {upcomingAppointments.map(appointment => (
                                <tr key={appointment._id}>
                                  <td className="d-flex align-items-center" > <FaUser style={{ color: '#15558d', marginRight: '10px' }} />{appointment.patient_name}   {appointment.patient_family}</td>


                                  <td>  {appointment.patient_phone}</td>
                                  <td> <FaMapMarkerAlt style={{ color: '#D32323', marginRight: '10px' }} />  {appointment.patient_governorate}</td>
                                  <td> {new Date(appointment.appointment_date).toLocaleDateString()}</td>
                                  <td> {appointment.start_hour}</td>
                                  <td>  {appointment.end_hour}</td>
                                  <td>
                                    <p className='{appointment.status}'>
                                      {appointment.status[0].toUpperCase() + appointment.status.slice(1)}
                                    </p>
                                  </td>


                                  <td>
                                    {appointment.status !== "canceled" && (
                                      <Button variant="danger" onClick={() => handleCancel(appointment._id)}>
                                        Cancel
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Tab>


                    </Tabs>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}


export default Dashboard;