import React, { useState, useEffect } from 'react';
import { FaStar, FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import { Container, Row, Col, Button } from 'react-bootstrap';
import Sidebar from '../SideBar';
// import '../../../App.css';
import Header from '../Header';
import Footer from '../../Footer';
import axios from '../../../api/axios'; 
import {getToken} from '../../../utils/auth';
import './style.css';
import '../../../utils/doctor_info';
import Doctor from '../../../utils/doctor_info';

const Reviews = () => {
  const [reviews, setReviews] = useState([]); 
  const token = getToken;

  const doc = new Doctor();

  const doctorId = doc.id;

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const storedReviews = JSON.parse(localStorage.getItem('reviews'));
    
        if (storedReviews) {
          setReviews(storedReviews);
        }

        //64dbb1ad7c3480aef8ff9a33
         const response = await axios.get(`/api/reviews/doctor/${doctorId}`, {
          //const response = await axios.get(`/api/reviews/doctor/64dbb1ad7c3480aef8ff9a33`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setReviews(response.data.reviews);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews(); 
  }, [token,doctorId]); 
  


    return(
      <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
          <div class="container-fluid">
              <div class="row align-items-center">
                  <div class="col-md-12 col-12">
                      <nav aria-label="breadcrumb" class="page-breadcrumb">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><a href="/">Home</a></li>
                              {/* You should change the breadcrumb Title */}
                              <li class="breadcrumb-item active" aria-current="page">Reviews</li>
                          </ol>
                      </nav>
                    
                      <h2 class="breadcrumb-title">Reviews</h2>
                  </div>
              </div>
          </div>
      </div>
    
      <div class="content">
          <div class="container-fluid">

              <div className="row">
           
                  <div className="col-md-5 col-lg-4 col-xl-3 ">

                      <Sidebar />

                  </div>
                

               
                  <div className="col-md-7 col-lg-8 col-xl-9">
                  {
                  reviews.length === 0 ? ( <>
                  <Container className="my-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' ,padding: 50}}>
  <Row className="mb-4" >
    <h3 style={{ textAlign: 'center' }}>No Reviews Available</h3>
  </Row>
  <Row className="mb-4" style={{ textAlign: 'center' }}>
    <img src={require('../../../assets/images/reviews.png')} className="me-3" alt="Reviews" />
  </Row>
</Container>

                    
                    
                  </> ): (
                  reviews.map((review, index) => (
         <Container key={index} className="my-3">
         <Row className=" mb-4 ">
           <Col xs={12} md={8}>
             <div className=" align-items-center">
               <img src={require('../../../assets/images/patients/placeholder.png')} roundedCircle width="80" height="80" className="me-3 profile" />
               <div>
               <br></br>
                 <p className="mb-0" style={{color:"green"}}  >Reviewed {review.date.toString().slice(0,16)}</p>
                <br></br>
                 <p className="mb-0">{review.review_text}</p>
               </div>
             </div>
           </Col>
           
             <div className="rating">
               {[1, 2, 3, 4, 5].map((_, starIndex) => (
                 <span
                   key={starIndex}
                   className={`star ${starIndex < review.rating ? 'filled' : ''}`}
                 >
                   <FaStar style={{ color: starIndex < review.rating ? 'rgb(213, 173, 53)'  : 'inherit' }} />
                 </span>
            ))}</div>
             
          </Row>
          
  
        </Container>
                  ))
                  )
                  }
        
        </div>

</div>

</div>
</div>
{/* Footer */}
<Footer />
{/* Footer */}
</div>
      )
    }
    
  
export default Reviews;