import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/Home'
import ChangePassword from './components/Doctors/ChangePassword';
import ProfileSettings from './components/Doctors/ProfileSettings';
import Booking from './components/Doctors/AddAppointment/Booking';
import BookingSuccess from './components/Doctors/AddAppointment/BookingSuccess';
import AvailableTimings from './components/Doctors/AvailableTimings';
import ScheduleTimings from './components/Doctors/ScheduleTimings';
import SocialMedia from './components/Doctors/SocialMedia';
import Reviews from './components/Doctors/Reviews';
import Dashboard from './components/Doctors/Dashboard';
import ViewAppointment from './components/Doctors/AddAppointment/ViewAppointment';
import AddTimeSlots from './components/Doctors/AvailableTimings/AddTimeSlots';
import Checkout from './components/Doctors/AddAppointment/Checkout';
import PatientProfile from './components/Doctors/PatientProfile';
import Register from './components/Doctors/Register';
import DoctorLogin from './components/Login';
import NotFound from './components/404';
import AddHours from './components/Doctors/ScheduleTimings/AddHours';
import './App.css';

const App = () => {

  const isUserSignedIn = !!localStorage.getItem('token'); // Check if doctor JWT token is found

  return (

    <Router basename='/'>
      <div style={{ paddingTop: '25px' }}>
        <Routes>
          {/* HomePage */}
          <Route path="/" element={<Home />} />
          {/* Doctor Activities */}
          <Route path="/Dashboard" element={isUserSignedIn ? <Dashboard /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/ProfileSettings" element={isUserSignedIn ? <ProfileSettings /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/AvailableTimings" element={isUserSignedIn ? <AvailableTimings /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/ScheduleTimings" element={isUserSignedIn ? <ScheduleTimings /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/AddHours" element={isUserSignedIn ? <AddHours /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/ChangePassword" element={isUserSignedIn ? <ChangePassword /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/Reviews" element={isUserSignedIn ? <Reviews /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/SocialMedia" element={isUserSignedIn ? <SocialMedia /> : <Navigate to="/DoctorLogin" />} />
          {/* Add Remaining Doctor Activities Routes */}
          <Route path="/AddTimeSlots" element={isUserSignedIn ? <AddTimeSlots /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/AddAppointment" element={isUserSignedIn ? <Booking /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/Checkout" element={isUserSignedIn ? <Checkout /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/BookingSuccess" element={isUserSignedIn ? <BookingSuccess /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/ViewAppointment" element={isUserSignedIn ? <ViewAppointment /> : <Navigate to="/DoctorLogin" />} />
          <Route path="/PatientProfile" element={isUserSignedIn ? <PatientProfile /> : <Navigate to="/DoctorLogin" />} />
          {/* Login Activities */}
          <Route path="/Register" element={<Register />} />
          <Route path="/DoctorLogin" element={<DoctorLogin />} />
          {/* Catch-all route */}
          <Route path="*" element={<NotFound />} /> 
        </Routes>
      </div>
    </Router>

  );
};


export default App;
