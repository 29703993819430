import React from 'react';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { BsEmojiSmile } from "react-icons/bs";
import { FaRegCompass, FaUserCheck } from "react-icons/fa";
import './style.css';
import doctorBanner from '../../assets/images/doctor-banner.png';
import HomeHeader from './HomeHeader'
import Footer from '../Footer';

const Home = () => {
    return (

        <div class='body-home'>
            <HomeHeader />

            <Container>

                <Row>

                    <Col>
                        <Form>
                            <Row>
                                <h3 >Search Doctor,</h3>

                            </Row>
                            <Row>
                                <h3>Make an Appointment</h3>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="location">
                                    <Form.Label><FaRegCompass className="icon1" /> Location</Form.Label>
                                    <Form.Control as="select">
                                    <option>Beirut</option>
                                    <option>Saida (Sidon)</option>
                                    <option>Nabatieh</option>
                                    <option>Tyre (Sour)</option>
                                    <option>Bekaa (Bekkaa)</option>
                                    <option>Zahle</option>
                                    <option>Byblos (Jbeil)</option>
                                    <option>Tripoli (Trablus)</option>
                                    <option>Aley</option>
                                    <option>Baalbek</option>
                                    <option>Jounieh</option>
                                    <option>Chouf</option>
                                    <option>Mount Lebanon</option>
                                    <option>Akkar</option>
                                    <option>Keserwan</option>
                                    <option>Metn</option>
                                    <option>Zgharta</option>
                                    <option>Bint Jbeil</option>
                                    <option>Hasbaya</option>
                                    <option>Rachaya</option>
                                    </Form.Control>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                               
                                    <Form.Group as={Col} controlId="gender">
                                        <Form.Label><BsEmojiSmile className="icon2" /> Gender</Form.Label>
                                        <Form.Control as="select">
                                            <option>Male</option>
                                            <option>Female</option>
                                        </Form.Control>
                                    </Form.Group>
                                
                               
                                    <Form.Group as={Col} controlId="department">
                                        <Form.Label><FaUserCheck className="icon3" /> Department</Form.Label>
                                        <Form.Control as="select" >
                                        <option>Anesthesiology</option>
                                        <option>Cardiology</option>
                                        <option>Dermatology</option>
                                        <option>Endocrinology</option>
                                        <option>Gastroenterology</option>
                                        <option>Geriatrics</option>
                                        <option>Hematology</option>
                                        <option>Infectious Diseases</option>
                                        <option>Internal Medicine</option>
                                        <option>Nephrology</option>
                                        <option>Neurology</option>
                                        <option>Obstetrics and Gynecology</option>
                                        <option>Oncology</option>
                                        <option>Ophthalmology</option>
                                        <option>Orthopedics</option>
                                        <option>Otorhinolaryngology (ENT)</option>
                                        <option>Pediatrics</option>
                                        <option>Psychiatry</option>
                                        <option>Pulmonology</option>
                                        <option>Radiology</option>
                                        <option>Rheumatology</option>
                                        <option>General Surgery</option>
                                        <option>Cardiothoracic Surgery</option>
                                        <option>Colorectal Surgery</option>
                                        <option>Neurosurgery</option>
                                        <option>Plastic and Reconstructive Surgery</option>
                                        <option>Transplant Surgery</option>
                                        <option>Urology</option>
                                        <option>Vascular Surgery</option>
                                        <option>Emergency Medicine</option>
                                        <option>Allergy and Immunology</option>
                                        <option>Critical Care Medicine</option>
                                        <option>Physical Medicine and Rehabilitation (PM&R)</option>
                                        <option>Sports Medicine</option>
                                        <option>Pathology</option>
                                        <option>Nuclear Medicine</option>
                                        <option>Genetics</option>
                                        <option>Occupational Medicine</option>
                                        <option>Nutrition and Dietetics</option>
                                        <option>Palliative Care</option>
                                            
                                            
                                    
                                        </Form.Control>
                                    </Form.Group>
                                
                            </Row>
                            <Row><Button type="submit" href='/AddAppointment' style={{background:"#15558d",color: "#fff"}} className="make">MAKE APPOINTMENT</Button></Row>
                        </Form>
                    </Col>

                    <Col><Image src={doctorBanner} alt="Doctor" className="Dr" fluid /></Col>
                </Row>

            </Container>

            <Footer />
        </div>
    );
};

export default Home;
