import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'; // Import NavLink
import './style.css'
import { FaColumns, FaEdit, FaHourglassStart, FaStopwatch, FaCalendar, FaStar, FaUserCog, FaShareAlt, FaLock, FaSignOutAlt, FaPager } from "react-icons/fa";
import Doctor from '../../../utils/doctor_info';
import { logout } from '../../../utils/logout';

function Sidebar() {

  const doc = new Doctor();
  let profilePhoto = doc.photo;
  profilePhoto===""?profilePhoto="https://tabiby.hexapi.tech/images/doctors/placeholder.png":profilePhoto=profilePhoto;
  const doctorTitle = doc.title;
  const doctorName = doc.firstname + ' ' + doc.lastname;
  const displayedName = doctorTitle + ' ' + doctorName;
  const doctorSpeciality = doc.speciality;

  const [activePage, setActivePage] = useState('/Dashboard');

  const handleNavigation = (page) => {

    setActivePage(page);
  };
  useEffect(() => {
    handleNavigation(window.location.pathname);
    console.log('activePage updated:', activePage);
  }, [activePage]);

  const handleLogout = () => {
    logout();
    // You can add additional logic here, such as redirecting to the sign-in page
  };
  return (
    <div class="profile-sidebar">
      <div class="widget-profile pro-widget-content">
        <div class="profile-info-widget">
          <a href="#" class="booking-doc-img">
            <img src={profilePhoto} alt="Doctor" />
          </a>
          <div class="profile-det-info">
            <h3 style={{fontWeight:'bolder',fontSize:24}}>{displayedName}</h3>

            <div class="patient-details">
              <h5 class="mb-0">{doctorSpeciality}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-widget">
        <nav className="dashboard-menu">
          <ul>
            <li className={activePage === '/Dashboard' ? 'active' : ''}>
              <NavLink to="/Dashboard" >
                <FaColumns />
                <span> &nbsp;&nbsp;Dashboard</span>
              </NavLink>
            </li>
            <li className={activePage === '/AddAppointment' ? 'active' : ''}>
              <NavLink to="/AddAppointment" >
                <FaEdit />
                <span> &nbsp;&nbsp;Add Appointment</span>
              </NavLink>
            </li>
            <li className={activePage === '/ScheduleTimings' ? 'active' : ''}>
              <NavLink to="/ScheduleTimings">
                <FaHourglassStart />
                <span> &nbsp;&nbsp;Schedule Timings</span>
              </NavLink>
            </li>
            <li className={activePage === '/availableTimings' ? 'active' : ''}>
              <NavLink to="/availableTimings">
                <FaStopwatch />
                <span> &nbsp;&nbsp;Available Timings</span>
              </NavLink>
            </li>
            <li className={activePage === '/reviews' ? 'active' : ''}>
              <NavLink to="/reviews" >
                <FaStar />
                <span> &nbsp;&nbsp;Reviews</span>
              </NavLink>
            </li>
            <li className={activePage === '/ProfileSettings' ? 'active' : ''}>
              <NavLink to="/ProfileSettings">
                <FaUserCog />
                <span> &nbsp;&nbsp;Profile Settings</span>
              </NavLink>
            </li>
            <li className={activePage === '/SocialMedia' ? 'active' : ''}>
              <NavLink to="/SocialMedia" >
                <FaShareAlt />
                <span> &nbsp;&nbsp;Social Media</span>
              </NavLink>
            </li>
            <li className={activePage === '/ChangePassword' ? 'active' : ''}>
              <NavLink to="/ChangePassword" >
                <FaLock />
                <span> &nbsp;&nbsp;Change Password</span>
              </NavLink>
            </li>
            <li className={activePage === '/' ? 'active' : ''}>
              <NavLink onClick={handleLogout} >
                <FaSignOutAlt />
                <span> &nbsp;&nbsp;Logout</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>

    </div>

  );
}

export default Sidebar
