//create a class to get doctor name from local storage

class Doctor {
  id;
  firstname;
  lastname;
  photo;
  speciality;
  title;

  constructor() {
  
    let user;

    try {
      user = JSON.parse(localStorage.getItem('doctor'));
      this.id = user._id;
    this.photo = user.profile_image;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.speciality = user.specializations.toString();
    this.title = user.title;
    } catch (error) {
      // Handle the error here
      console.error('Error parsing user data from local storage:', error);
    }
    
  }
}
export default Doctor;