import React, { useState, useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Step2 from './Step1';
import Step3 from './Step2';
import Review from './Review';
import logo from '../../../assets/images/logo.png';
import '../../../App.css';
import { Navbar, Nav, Button as Button2 } from 'react-bootstrap';
import Footer from '../../Footer';
// import GeneralInfo from './Step1';
import axios from '../../../api/axios';
// import  GeneralInfo from './Step1';

const steps = ['Personal Information', 'Email', 'Email Verification'];

let pageNumber = 0;
let personalInfo = []
let emailInfo = []
let code=[]
const sendPersonalInfo = (data) => {
  personalInfo.pop()
  personalInfo.push(data)
}
const sendEmailInfo = (data) => {
  emailInfo.pop()
  emailInfo.push(data)
}
const sendCode = (data) => {
  code.pop()
  code.push(data['code'])
}
function getStepContent(step,updateOTPCode) {
  switch (step) {
    case 0:
      pageNumber = 0;
      return <Step2 sendPersonalInfo={sendPersonalInfo} />;
    case 1:
      pageNumber = 1;
      return <Step3 sendEmailInfo={sendEmailInfo} PaymentForm />;
    case 2:
      pageNumber = 2;
      return <Review updateOTPCode={updateOTPCode}/>;
    default:
      throw new Error('Unknown step');
  }
}

const Register = () => {

  // const inputStep2 = Step2();

  const [activeStep, setActiveStep] = React.useState(0);
  const [otpCode, setOTPCode] = React.useState('');
  // const [personalInfo, setPersonalInfo] = React.useState({hits: []});
  // const [emailInfo, setEmailInfo] = React.useState({});
  const updateOTPCode = (newCode) => {
    setOTPCode(newCode);
  };
  
  const handleNext = async (e) => {
    e.preventDefault();

    if (pageNumber === 1) {
      try {
        const response = await axios.post(
          '/api/doctors/register',
          {
            "title": personalInfo[0]['title'],
            "firstname": personalInfo[0]['firstname'],
            "middlename": personalInfo[0]['middlename'],
            "lastname": personalInfo[0]['lastname'],
            "gender": personalInfo[0]['gender'],
            "email": emailInfo[0]['email'],
            "phone": emailInfo[0]['phoneNumber'],
            "location": [
              33.255904,
              35.275933
            ]
          }
        );
        if (!response.data.success) {
          window.alert('An error occurred during Register.');
          return
        }
      } catch (error) {
        console.log(error)
        if (error.response && error.response.data) {
          window.alert(error.response.data.message || 'An error occurred during Register.');
        } else {
          window.alert('An error occurred during Register.');
        }
        return
      }
     
    }
     else if (pageNumber === 2) {
      console.log('code'  , otpCode );
      try {
        const response = await axios.post(
          '/api/users/verify_email',
          {
            "email":  emailInfo[0]['email'],
            "verificationCode":otpCode
          }
        );
        if (!response.data.success) {
          window.alert(response.data.message || 'An error occurred during verification.');
          return
        }
      } catch (error) {
        if (error.response && error.response.data) {
          window.alert(error.response.data.message || 'An error occurred during verification.');
          
        } else {
          window.alert('An error occurred during verification.');
        }
        return
      }
      
     }
    setActiveStep(activeStep + 1);


  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };




  return (
    <React.Fragment>
      <CssBaseline />
      {/* Header */}
      <Navbar fixed="top" expand="lg" className="navbar"   >
        <Navbar.Brand href="/">
          <img
            src={logo}
            alt="Logo"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/" className='menu-option'>Home</Nav.Link>
            <Nav.Link href="/Dashboard" className='menu-option'>Doctor Dashboard</Nav.Link>
            <Nav.Link href="#" className='menu-option'>Patient Dashboard</Nav.Link>
            <Nav.Link href="#" className='menu-option'>Calendar</Nav.Link>
          </Nav>
          <Button2 variant="primary" style={{backgroundColor:'#15558d',borderColor:'#15558d'}} href='/DoctorLogin'>Login / Signup</Button2>
        </Navbar.Collapse>
      </Navbar>
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  {/* You should change the breadcrumb Title */}
                  <li class="breadcrumb-item active" aria-current="page">Doctor Register</li>
                </ol>
              </nav>
              {/* You should change the breadcrumb Title */}
              <h2 class="breadcrumb-title">Doctor Registration</h2>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcrumb */}
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Registration
          </Typography>
          <Stepper activeStep={activeStep}  sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Thank you for using Tabiby Platform
              </Typography>
              <Typography variant="subtitle1">
                Your information will be processed soon. We have received your request
                , and will send you an update when it is
                verified by the company. You can call us to finish the process faster.
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep,updateOTPCode)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  style={{backgroundColor:'#15558d',borderColor:'#15558d'}}
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {/* {activeStep === steps.length - 1 ? 'Finish' : 'Next'} */}

                  {pageNumber === 2 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
      </Container>
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </React.Fragment>
  );
}

export default Register;