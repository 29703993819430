import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';


export default function PaymentForm(props) {

  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] =  useState('');


  function handleChange(event) {
    event.preventDefault();
    if (event.target.id === 'email') {
      setEmail(event.target.value);
    }
    else if (event.target.id === 'phoneNumber') {
      setPhoneNumber(event.target.value);
    }
    props.sendEmailInfo({
      email: email,
      phoneNumber: phoneNumber,

    })
  }


  return (
    <React.Fragment>
      <Typography variant="h9" gutterBottom>
        Provide us with a your phone number to contact you. After clicking Next, a code will be sent to this number to verify your account.
      </Typography>
      <Grid container spacing={3}>

        <Grid item xs={12} md={6}>
          <TextField
            required
            id="email"
            label="E-mail"
            fullWidth
            variant="standard"
            // value={email}
            onChange={handleChange}
          />
          <TextField
            required
            id="phoneNumber"
            label="Phone number"
            fullWidth
            type="number"
            // value={phoneNumber}
            onChange={handleChange}
            variant="standard"
          />
        </Grid>

      </Grid>
    </React.Fragment>
  );
}