
import './style.css';
import '../../../App.css';
import Header from '../Header';
import Footer from '../../Footer';
import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Table, Button, Image, } from 'react-bootstrap';
import { FaEdit, FaUserMd, FaTrash } from "react-icons/fa";
import doctorImg from '../../../assets/images/patients/patient1.jpg';


const PatientProfile = () => {
    //main
    const [appointments, setAppointments] = useState([]);

    // Simulate an API call
    const fetchAppointments = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve([
                    { doctor: "Doctor Name", note: "Dentist", date: "14 Nov 2022", date2: "14 Nov 2022", date3: "14 Nov 2022", time: "9:30am", status: "confirmed" },
                    { doctor: "Doctor Name", note: "Dentist", date: "14 Nov 2022", date2: "14 Nov 2022", date3: "14 Nov 2022", time: "9:30am", status: "pending" },
                    { doctor: "Doctor Name", note: "Dentist", date: "14 Nov 2022", date2: "14 Nov 2022", date3: "14 Nov 2022", time: "9:30am", status: "canceled" },
                    // ... more appointments
                ]);
            }, 2000);
        });
    };
    //main
    useEffect(() => {
        fetchAppointments().then(data => {
            setAppointments(data);
        });
    }, []);
    const [patientImage, setPatientImage] = useState(require('../../../assets/images/patients/patient1.jpg'));


    const [patientData] = useState({
        name: 'Richard Wilson',
        patientId: 'PT0016',
        location: 'Location, Lebanon',
    });

    const [patientInfo] = useState({
        phone: '+123-456-7890',
        age: '30 Years',
        gender: 'Male'

    });
    const [bookings, setBookings] = useState([
        {
            doctorImage: require('../../../assets/images/doctors/doctor-02.jpg'),
            doctorName: 'Dr. Darren Elder',
            specialty: 'Dentist',
            bookingDate: '14 Nov 2023 5.00 PM',
        },
        {
            doctorImage: require('../../../assets/images/doctors/doctor-02.jpg'),
            doctorName: 'Dr. Darren Elder',
            specialty: 'Dentist',
            bookingDate: '12 Nov 2023 11.00 AM',
        },
    ]);

    return (
        <div>
            {/* Header */}
            <Header />
            {/* Header */}

            {/* Breadcrumb */}
            <div class="breadcrumb-bar">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-12 col-12">
                            <nav aria-label="breadcrumb" class="page-breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                                    {/* You should change the breadcrumb Title */}
                                    <li class="breadcrumb-item active" aria-current="page">Profile</li>
                                </ol>
                            </nav>

                            <h2 class="breadcrumb-title">Patient Profile</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content">
                <div class="container-fluid">

                    <div className="row row-gap-3">

                        <div className="col-12 col-md-5 col-lg-4 col-xl-3 ">
                            <div className="patientprofile">
                                <div className="patientbox">
                                    <div className="patient">
                                        <img src={patientImage} alt="Icon 1" />
                                        <p className='my-2'>{patientData.name}</p>
                                        <p className='mb-2'> <span className="bolded">Patient ID:</span> {patientData.patientId} </p>
                                        <p className='mb-3'><FaMapMarkerAlt /> {patientData.location}</p>
                                    </div>
                                    <div className="patient-info">
                                        <div className="info-item">
                                            <span className="bolded">Phone</span>
                                            <span className="align-right">{patientInfo.phone}</span>
                                        </div>
                                        <div className="info-item">
                                            <span className="bolded">Age</span>
                                            <span className="align-right">{patientInfo.age}, {patientInfo.gender}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="patientbox1 mt-3">
                                    <p className="bolded">Last Booking</p>
                                    {bookings.map((booking, index) => (
                                        <div className="booking align-items-center m-0 py-3" key={index}>
                                            <img src={booking.doctorImage} alt={`Doctor ${index}`} />
                                            <div className="booking-details">
                                                <div className="booking-text">
                                                    <p className="mb-1">{booking.doctorName}</p>
                                                    <p className="mb-1">{booking.specialty}</p>
                                                </div>
                                                <p className="booking-date mb-0">{booking.bookingDate}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-7 col-lg-8 col-xl-9">


                            <div className="card">
                                <div className="table-title">
                                    <h3>Patients' Appointments</h3>
                                </div>
                                <div className='px-4 py-2 overflow-auto'>
                                    <Table striped bordered hover>
                                        <tbody>
                                            {appointments.map((appointment, index) => (
                                                <tr key={index}>
                                                    <td className="d-flex align-items-center">
                                                        <Image src={doctorImg} roundedCircle width="40" className="mr-3" />
                                                        <div>
                                                            <div><FaUserMd /> {appointment.doctor}</div>
                                                            <small>{appointment.note}</small>
                                                        </div>
                                                    </td>
                                                    <td>{appointment.date}<br /><span className="text-primary">{appointment.time}</span></td>
                                                    <td>{appointment.date2}</td>
                                                    <td>{appointment.date3}</td>

                                                    <td>{appointment.date2}</td>
                                                    <td><span id="status" className={appointment.status}>{appointment.status}</span></td>
                                                    <td className="text-end"><Button variant="outline-primary success m-1" size="sm"><FaEdit /> Edit</Button>
                                                        {/* if else for the status  */}
                                                        {appointment.status === 'pending' && (
                                                            <Button variant="outline-primary danger m-1" size="sm">
                                                                <FaTrash /> Cancel
                                                            </Button>
                                                        )}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>



                        </div>

                    </div>

                </div>
            </div>

            <Footer />

        </div>
    )
}

export default PatientProfile;