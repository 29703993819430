import React, { useState, useRef } from 'react';
import '../../../App.css';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import Footer from '../../Footer';
import { BsFillStarFill } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import Sidebar from '../SideBar';
import './BookingSuccess';
import './Checkout';
import './style.css';
import Slider from "react-slick"; // Import the Slider component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Row from 'react-bootstrap/Row';

const Booking = () => {
    const [selectedDate, setSelectedDate] = useState('');
    const [times, setTimes] = useState({});
    const sliderRef = useRef(null);

    const generateDatesForMonth = () => {
        const startDate = new Date(2023, 10, 1);
        const endDate = new Date(2023, 10, 30);
        const dates = [];
        while (startDate <= endDate) {
            dates.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
        }
        return dates;
    };

    const handleTimeChange = (event) => {
        const { name, value } = event.target;
        setTimes((prevTimes) => ({
            ...prevTimes,
            [selectedDate]: value,
        }));
    };
    const handleTimeButtonClick = (time) => {
        setTimes((prevTimes) => ({
            ...prevTimes,
            [selectedDate]: time,
        }));
    };

    const settings = {
        infinite: false,
        slidesToShow: 7,
        slidesToScroll: 1,
    };

    const timeSlots = ['9:00 AM', '10:00 AM', '11:00 AM', '12:00 AM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM'];
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dates = generateDatesForMonth();
    const navigate = useNavigate();

    return (
      <div>
      <Header />
      <div class="breadcrumb-bar">
          <div class="container-fluid">
              <div class="row align-items-center">
                  <div class="col-md-12 col-12">
                      <nav aria-label="breadcrumb" class="page-breadcrumb">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><a href="/Dashboard">Dashboard</a></li>
                              <li class="breadcrumb-item active" aria-current="page">Add Appointment</li>
                          </ol>
                      </nav>
                      <h2 class="breadcrumb-title">Add Appointment</h2>
                  </div>
              </div>
          </div>
      </div>
      <div class="content">
          <div class="container-fluid">
              <div className="row align-items-start">
                  <div className="col-md-5 col-lg-4 col-xl-3 ">
                      <Sidebar />
                  </div>
                 
                  <div className="col-md-7 col-lg-8 col-xl-9">
                    <Row> <h6 style={{color:'red'}}>This Page is Static. It doesn't fetch data from Backend</h6></Row>
                    
                      <div className="appt-container">
                          
                          <table>
                                    <tr>
                                        <td><img src={require('../../../assets/images/doctors/doctor-02.jpg')} class="Dr-02" alt="Dr.Darren" /></td>
                                        <td>
                                            <h5>Dr. John Doe</h5>
                                            <p class="pt" size="10%">
                                                <BsFillStarFill color="#d5ad35" /> <BsFillStarFill color="#d5ad35" /> <BsFillStarFill color="#d5ad35" /> <BsFillStarFill color="#d5ad35" /> <BsFillStarFill color="grey" class="star" />35
                                            </p>
                                            <p color="grey"><FaLocationDot color="grey" />Beirut</p>
                                        </td>
                                    </tr>
                                </table>
                                <Slider {...settings} ref={sliderRef}>
    {dates.map((date) => (
        <div 
            key={date} 
            onClick={() => setSelectedDate(date)} 
            className={`weekday ${selectedDate && selectedDate.toDateString() === date.toDateString() ? 'selected-date' : ''}`}
        >
            <p>{weekdays[date.getDay()]}</p>
            <p>{date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}</p>
        </div>
    ))}
</Slider>
        
        <div className="timing-containeradd">
            {timeSlots.map((time) => (
                <button
                    key={time}
                    className={`buttontime ${selectedDate && times[selectedDate] === time ? 'selected-time' : ''}`}
                    onClick={() => handleTimeButtonClick(time)}
                >
                    {selectedDate && times[selectedDate] === time && <FaCheck color="white" className="check" />} {time}
                </button>
            ))}
        </div>
                          <button className="btn btn-primary mt-4" style={{backgroundColor:'#15558d'}} onClick={() => navigate("/Checkout")}>
                              Booking Success
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <Footer />
  </div>
)
}

export default Booking;






