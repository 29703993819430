import React, {useState, useEffect } from 'react'
import './style.css';
import '../../App.css';
import logo from '../../assets/images/logo.png';
import Footer from '../Footer';
import LoginBanner from '../../assets/images/login-banner.png';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Navbar, Nav, Button } from 'react-bootstrap';
import axios from  '../../api/axios';

const DoctorLogin = () => {
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [doctor, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loginError] = useState('');
  

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        '/api/users/login',
        {
          username: username,
          password: password,
        }
      );
      if (response.data.success) {
        setUser(response.data.user);
        const token = response.data.token;
        const doctor = response.data.doctor;
        setToken(token); 
        localStorage.setItem('token', token);
        localStorage.setItem('doctor', JSON.stringify(doctor));
        window.location.href = '/Dashboard';
        console.log('Login successful:', response.data);
      }
      else window.alert(response.data.message);
      
    } catch (error) {
      if (error.response && error.response.data) {
        window.alert(error.response.data.message || 'An error occurred during login.');
      } else {
        window.alert('An error occurred during login.');
      }
    }
  };
  const handleLogout = () => {
    setUser(null);
    setToken(null);
    //clear local storage
    localStorage.removeItem('token');
  
    window.location.href = '/DoctorLogin';
};

    return (
        <div>
            {/* Header */}
            <Navbar  fixed ="top" expand="lg" className="navbar"   >
      <Navbar.Brand href="/">
        <img
          src={logo}
          alt="Logo"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/" className='menu-option'>Home</Nav.Link>
          <Nav.Link href="/Dashboard" className='menu-option'>Doctor Dashboard</Nav.Link>
          <Nav.Link href="#" className='menu-option'>Patient Dashboard</Nav.Link>
          <Nav.Link href="#" className='menu-option'>Calendar</Nav.Link>
        </Nav>
        <Button variant="primary" href='/DoctorLogin' style={{backgroundColor:'#15558d',borderColor:'#15558d'}}>Login / Signup</Button>
      </Navbar.Collapse>
    </Navbar>
            {/* Header */}

            
            {/* Body Content */}
            <div >
                <div class="container-fluid">

                    <div className="row">
                    <div className="div-row">
      <div className="div-col-md">
        <img className="login-banner-png" alt="Login banner png" src={LoginBanner} />
      </div>
      <div className="div">
        <div className="div-login-header">
          <h2 className="heading-forgot">Login Tabiby</h2>
          <p className="text-wrapper">
            Enter your username and password that were sent to you
          </p>
        </div>
        <form onSubmit={handleLogin}>
        <Grid item xs={12}>
          <TextField
            required
            onChange={e => setUsername(e.target.value)}
            id="username"
            name="username"
            label="username"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid> 
        <Grid item xs={12}>
          <TextField
            required
            onChange={e => setPassword(e.target.value)}
            id="password"
            name="password"
            label="password"
            type="password"
            fullWidth
            autoComplete="middle-name"
            variant="standard"
          />
        </Grid>
                {loginError && <p className="error-message">{loginError}</p>}
                                            <Button type="submit" style={{backgroundColor:'#15558d',borderColor:'#15558d'}} className="button mt-3">
                                                <div className="text-wrapper-2">Login</div>
                                            </Button>
                                        </form>
        <h6 className="text-wrapper-3">Is it your first time?</h6>
        <a href="/Register"><div className="button2">
          <div className="text-wrapper-3" >Create Account</div>
        </div></a>
      </div>
    </div>
                    </div>

                </div>
            </div>
            {/* Footer */}
            <Footer />
            {/* Footer */}
        </div>
    )
}

export default DoctorLogin;


