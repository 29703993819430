import React from "react";
import "./style.css";
import Sidebar from '../SideBar';
import '../../../App.css';
import Header from '../Header';
import Footer from '../../Footer';
import "./Booking";
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
export const ViewAppointment = () => {
  const navigate = useNavigate();
  return (
    <div>
    {/* Header */}
    <Header />
    {/* Header */}

    {/* Breadcrumb */}
    <div class="breadcrumb-bar">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-md-12 col-12">
                    <nav aria-label="breadcrumb" class="page-breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            {/* You should change the breadcrumb Title */}
                            <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
                        </ol>
                    </nav>
                    {/* You should change the breadcrumb Title */}
                    <h2 class="breadcrumb-title">View Appointment</h2>
                </div>
            </div>
        </div>
    </div>
    {/* Breadcrumb */}
    {/* Body Content */}
    <div class="content">
        <div class="container-fluid">

            <div className="row">
                {/* Sidebar Component */}
                <div className="col-md-5 col-lg-4 col-xl-3 ">

                  <Sidebar />

                </div>
                
                <div className="col-md-7 col-lg-8 col-xl-9">
      <div className="div-invoice-content">
          <div className="div-row2">
              <img src={require('../../../../src/assets/images/logo.png')} className=" logo-png" />
              <div className="p-invoice-details-wrapper">
                  <div className="p-invoice-details">
                      <p className="strong-appt">
                          <span className="text-wrapper">Appt #:</span>
                          <span className="span"> 00124</span>
                      </p>
                      <p className="strong-issued-in">
                          <span className="text-wrapper">Issued in:</span>
                          <span className="span"> 20/07/2023</span>
                      </p>
                  </div>
              </div>
          </div>
          <div className="div">
              <div className="div-invoice-info-wrapper">
                  <div className="div-invoice-info">
                      <div className="strong-doctor-clinic">Doctor / Clinic</div>
                      <div className="dr-darren-elder-wrapper">
                          <p className="dr-darren-elder">
                              Dr. Darren Elder
                              <br />
                              806 Twin Willow Lane, Old Forge,
                              <br />
                              Newyork, USA
                          </p>
                      </div>
                  </div>
              </div>
              <div className="div-invoice-info-wrapper">
                  <div className="div-invoice-info-2">
                      <div className="strong-patient">Patient</div>
                      <div className="walter-roberson-wrapper">
                          <div className="walter-roberson">
                              Walter Roberson
                              <br />
                              <br />
                              Beirut
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className='px-4 py-2 overflow-auto'>
              <Table striped bordered hover>
                  <thead>
                      <tr>
                          <th>Description</th>
                          <th>Date</th>
                          <th>Hours</th>
                          <th>Note</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>General Consultation</td>
                          <td>17-11-2013</td>
                          <td>10:00</td>
                          <td>Nothing Urgent</td>
                      </tr>
                  </tbody>
              </Table>
          </div>
          <div className="div-other-info">
              <div className="heading-other mt-3">Other information</div>
              <p className="p">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed dictum ligula, cursus blandit risus.
              </p>
              <button onClick={() => navigate("/Dashboard")} className="btn btn-primary mt-1" style={{backgroundColor:'#15558d'}}>Return Back</button>
          </div>
      </div>
      </div>

</div>

</div>
</div>
{/* Footer */}
<Footer />
{/* Footer */}
</div>
  );
};
export default ViewAppointment;