import React, { useState } from "react";
import Sidebar from '../SideBar';
import '../../../App.css';
import Header from '../Header';
import Footer from '../../Footer';
// eslint-disable-next-line
import { Button, Image, Form, Row, Col, InputGroup } from 'react-bootstrap';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { IconButton } from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'
const ProfileSettings = () => {
  // eslint-disable-next-line
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("https://via.placeholder.com/150"); // Placeholder image
  const [clinicImages, setClinicImages] = useState([]); // Added for multiple clinic images
  const [selectedPriceOption, setSelectedPriceOption] = useState(null);
  const [services, setServices] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  //for add more education
  const [educations, setEducations] = useState([
    { institution: '', degree: '', year: '' }
  ]);
  //for add more experience
  const [experiences, setExperience] = useState([
    { Hospitalname: '', from: '', to: '', designation: '' }
  ]);
  //for add more Awards
  const [awards, setAwards] = useState([
    { Awards: '', Year: '' }
  ]);
  //for ad more memberships
  const [memberships, setMemberships] = useState([
    { Memberships: '' }
  ]);
  // for registration addmore
  const [registration, setRegistration] = useState([
    { Registration: '', Year: '' }
  ]);

  const addMoreEducation = () => {
    setEducations([
      ...educations,
      { institution: '', degree: '', year: '' }
    ]);
  };
  const addMoreExperience = () => {
    setExperience([
      ...experiences,
      { Hospitalname: '', from: '', to: '', designation: '' }
    ]);
  };
  // ADD AWARDS FUNCTION
  const addMoreAwards = () => {
    setAwards([
      ...awards,
      { Awards: '', Year: '' }
    ]);
  };

  // registration add more function
  const addMoreRegistration = () => {
    setRegistration([
      ...registration,
      { Registration: '', Year: '' }
    ]);
  };
  // memberships add more function 
  const addMoreMemberships = () => {
    setMemberships([
      ...memberships,
      { Memberships: '' }
    ]);
  };
  //eductaion show less function 
  const removeLastEducation = () => {
    if (educations.length > 1) {
      setEducations(educations.slice(0, -1));
    }
  };
  //experience show less
  const removeLastExperience = () => {
    if (experiences.length > 1) {
      setEducations(educations.slice(0, -1));
    }
  };
  //awards show less
  const removeLastAwards = () => {
    if (awards.length > 1) {
      setAwards(awards.slice(0, -1));
    }
  };
  //membership sow less
  const removeLastMembership = () => {
    if (memberships.length > 1) {
      setMemberships(memberships.slice(0, -1));
    }
  };
  //registration showless
  const removeLastRegistrations = () => {
    if (registration.length > 1) {
      setRegistration(registration.slice(0, -1));
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
    }
  };
  const handleClinicImagesChange = (e) => { // Added for multiple clinic images
    if (e.target.files) {
      let fileList = Array.from(e.target.files);
      setClinicImages(fileList.map(file => URL.createObjectURL(file)));
    }
  };
  const handlePriceOptionChange = (e) => {
    setSelectedPriceOption(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // handle form submission here...
  };

  return (
    <div>
      {/* Header */}
      <Header />
      {/* Header */}

      {/* Breadcrumb */}
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-12">
              <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>

                  <li class="breadcrumb-item active" aria-current="page">Profile Settings</li>
                </ol>
              </nav>

              <h2 class="breadcrumb-title">Profile Settings  Page</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-fluid">

          <div className="row">

            <div className="col-md-5 col-lg-4 col-xl-3 ">

              <Sidebar />

            </div>


            <div className="col-md-7 col-lg-8 col-xl-9">
              <>
              <Row> <h6 style={{color:'red'}}>This Page is Static. It doesn't send data to Backend</h6></Row>
                    
                <Form onSubmit={handleFormSubmit} style={{backgroundColor:'#fff',margin:10,borderRadius:20}}>
                  <div className="mb-3" style={{padding:20}}>
                    <Row>
                      <Col md={4}>
                        <Image src={imageUrl} roundedCircle fluid />
                        <Form.Group>

                          <Form.Label>Upload Profile Picture</Form.Label>
                          <Form.Control type="file" onChange={handleImageChange} accept="image/*" />
                        </Form.Group>
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>Username</Form.Label>
                              <Form.Control type="text" placeholder="Enter username" />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Phone Number</Form.Label>
                              <Form.Control type="tel" placeholder="Enter phone number" />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control type="text" placeholder="Enter last name" />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="email" placeholder="Enter email" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>Date of Birth</Form.Label>
                              <Form.Control type="date" />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>Gender</Form.Label>
                              <Form.Control as="select" defaultValue="Choose...">
                                <option disabled>Choose...</option>
                                <option>Male</option>
                                <option>Female</option>
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div style={{padding:20}}>
                    <h2>About Me</h2>
                    <Form onSubmit={handleFormSubmit}>
                      <Form.Group>
                        <Form.Label>Biography</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter your biography here..." />
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="mb-3" style={{padding:20}}>
                    <h2>Clinic Details</h2>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Clinic Name</Form.Label>
                          <Form.Control type="text" placeholder="Enter clinic name" />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Clinic Address</Form.Label>
                          <Form.Control type="text" placeholder="Enter clinic address" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Form.Group>
                          <Form.Label>Upload Clinic Images</Form.Label>
                          <Form.Control type="file" onChange={handleClinicImagesChange} accept="image/*" multiple />
                        </Form.Group>
                        <Row>
                          {clinicImages.map((image, index) => (
                            <Col md={3} key={index}>
                              <Image src={image} thumbnail />
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-3" style={{padding:20}}>
                    <h2>Address Details</h2>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Address Line 1</Form.Label>
                          <Form.Control type="text" placeholder="Enter address line 1" />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Country</Form.Label>
                          <Form.Control type="text" placeholder="Enter country" />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>City</Form.Label>
                          <Form.Control type="text" placeholder="Enter city" />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Address Line 2</Form.Label>
                          <Form.Control type="text" placeholder="Enter address line 2" />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Postal Code</Form.Label>
                          <Form.Control type="text" placeholder="Enter postal code" />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>State</Form.Label>
                          <Form.Control type="text" placeholder="Enter state" />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-3" style={{padding:20}}>
                    <h2>Price Details</h2>
                    <Form.Group>
                      <Form.Label>Choose Price</Form.Label>
                      <Form.Check
                        type="radio"
                        label="Free"
                        name="priceOptions"
                        id="free"
                        value="free"
                        onChange={handlePriceOptionChange}
                      />
                      <Form.Check
                        type="radio"
                        label="Custom Price (per hour)"
                        name="priceOptions"
                        id="customPrice"
                        value="custom"
                        onChange={handlePriceOptionChange}
                      />
                      {selectedPriceOption === 'custom' && (
                        <Form.Control type="number" placeholder="Enter custom price per hour" />
                      )}
                    </Form.Group>
                  </div>
                  <div className="mb-3" style={{padding:20}}>
                    <h2>Services and Specializations</h2>
                    <h3>Services</h3>
                    <TagsInput value={services} onChange={setServices}
                      inputProps={{
                        placeholder: "Enter Service"

                      }}
                    />
                    Type and press enter to add a service
                  </div>
                  <div className="mb-3" style={{padding:20}}>
                    <h3>Specializations</h3>
                    <TagsInput value={specializations} onChange={setSpecializations}
                      inputProps={{
                        placeholder: "Enter Specialization"
                      }}
                    />
                  </div>
                  Type and press enter to add a specialization

                  <div className="mb-3" style={{padding:20}}>
                    <h2>Education</h2>
                    {educations.map((education, index) => (
                      <Row key={index}>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Institution</Form.Label>
                            <Form.Control type="text" placeholder="Enter Institution" />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Degree</Form.Label>
                            <Form.Control type="text" placeholder="Enter Degree" />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Year</Form.Label>
                            <Form.Control type="number" placeholder="Enter Year" />
                          </Form.Group>
                        </Col>
                      </Row>
                    ))}
                    <IconButton color="primary" onClick={addMoreEducation} >
                      <ControlPointIcon style={{ color: '#4267B2' }} />
                    </IconButton>
                    Add More
                    <IconButton color="secondary" onClick={removeLastEducation}>
                      <RemoveCircleIcon style={{ color: '#FF0000' }} />
                    </IconButton>
                    Show Less
                  </div>


                  <div className="mb-3" style={{padding:20}}>
                    <h2>Experience</h2>
                    {experiences.map((experience, index) => (
                      <Row key={index}>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Hospitalname</Form.Label>
                            <Form.Control type="text" placeholder="Enter Hospitalname" />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>From</Form.Label>
                            <Form.Control type="text" placeholder="" />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>to</Form.Label>
                            <Form.Control type="number" placeholder="" />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label> designation</Form.Label>
                            <Form.Control type="number" placeholder="" />
                          </Form.Group>
                        </Col>
                      </Row>
                    ))}
                    <IconButton color="primary" onClick={addMoreExperience} >
                      <ControlPointIcon style={{ color: '#4267B2' }} />
                    </IconButton>
                    Add More
                    <IconButton color="secondary" onClick={removeLastExperience}>
                      <RemoveCircleIcon style={{ color: '#FF0000' }} />
                    </IconButton>
                    Show Less
                  </div>
                  <div className="mb-3" style={{padding:20}}>
                    <h2>Awards</h2>
                    {awards.map((awards, index) => (
                      <Row key={index}>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Awards</Form.Label>
                            <Form.Control type="text" placeholder="Enter Hospitalname" />
                          </Form.Group>
                        </Col>

                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Year</Form.Label>
                            <Form.Control type="number" placeholder="" />
                          </Form.Group>
                        </Col>

                      </Row>
                    ))}
                    <IconButton color="primary" onClick={addMoreAwards} >
                      <ControlPointIcon style={{ color: '#4267B2' }} />
                    </IconButton>
                    Add More
                    <IconButton color="secondary" onClick={removeLastAwards}>
                      <RemoveCircleIcon style={{ color: '#FF0000' }} />
                    </IconButton>
                    Show Less
                  </div>
                  <div className="mb-3" style={{padding:20}}>
                    <h2>Memberships</h2>
                    {memberships.map((Memberships, index) => (
                      <Row key={index}>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Memberships</Form.Label>
                            <Form.Control type="text" placeholder=" " />
                          </Form.Group>
                        </Col>

                      </Row>
                    ))}
                    <IconButton color="primary" onClick={addMoreMemberships} >
                      <ControlPointIcon style={{ color: '#4267B2' }} />
                    </IconButton>
                    Add More
                    <IconButton color="secondary" onClick={removeLastMembership}>
                      <RemoveCircleIcon style={{ color: '#FF0000' }} />
                    </IconButton>
                    Show Less
                  </div>
                  <div className="mb-3" style={{padding:20}}>
                    <h2>Registration</h2>
                    {registration.map((awards, index) => (
                      <Row key={index}>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Registration</Form.Label>
                            <Form.Control type="text" placeholder=" " />
                          </Form.Group>
                        </Col>

                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Year</Form.Label>
                            <Form.Control type="number" placeholder="" />
                          </Form.Group>
                        </Col>

                      </Row>
                    ))}
                    <IconButton color="primary" onClick={addMoreRegistration} >
                      <ControlPointIcon style={{ color: '#4267B2' }} />
                    </IconButton>
                    Add More
                    <IconButton color="secondary" onClick={removeLastRegistrations}>
                      <RemoveCircleIcon style={{ color: '#FF0000' }} />
                    </IconButton>
                    Show Less
                  </div>
                  <div style={{textAlign:'center'}}>
                  <Button variant="primary" type="submit" style={{width:'40%',backgroundColor: '#15558d'}}>
                    Submit
                  </Button></div>
                  
                </Form>
              </>







            </div>

          </div>
<br></br>
        </div>
      </div>
      
      <Footer />

    </div>

  );
};
export default ProfileSettings;