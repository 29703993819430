import React, { useState } from 'react';
import { Container, Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import logo from '../../../assets/images/logo.png';
import './style.css';
import Doctor from '../../../utils/doctor_info';
import { logout } from '../../../utils/logout';

const Header = () => {
    const [showOptions, setShowOptions] = useState(false);

    const handleOptionsClick = () => {
        setShowOptions(!showOptions);
    };
    const doc=new Doctor();
    let profilePic=doc.photo;
    profilePic===""?profilePic="https://tabiby.hexapi.tech/images/doctors/placeholder.png":profilePic=profilePic;
    const handleLogout = () => {
        logout();
        // You can add additional logic here, such as redirecting to the sign-in page
      };
    return (
        
        <Navbar fixed="top" expand="lg" className="header-container">
             <Container fluid>
            <Navbar.Brand href="/">
                <Image src={logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                 <Nav className='ml-auto' >  
                    <NavDropdown title={<Image src={profilePic} onClick={handleOptionsClick} className='imageview' />}  id="basic-nav-dropdown" className='header-navbar-rht'>
                        <NavDropdown.Item href="/Dashboard">Dashboard</NavDropdown.Item>
                        <NavDropdown.Item href="/ProfileSettings">Profile Settings</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                    </NavDropdown>
                 
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
      
    );
};

export default Header;
