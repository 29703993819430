import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import Select from 'react-select';
import { FaBookMedical, FaEdit,  } from 'react-icons/fa';
import { FaBatteryEmpty } from 'react-icons/fa';
import { FaCoffee, FaCalendarAlt, FaExclamationTriangle, FaNewspaper } from 'react-icons/fa';
import axios from '../../../api/axios'; 
import { getToken } from '../../../utils/auth';
import Doctor from '../../../utils/doctor_info';
import Sidebar from '../SideBar';
import '../../../App.css';
import Header from '../Header';
import Footer from '../../Footer';
import './style.css'
import { FaDeleteLeft } from 'react-icons/fa6';

const token = getToken;
const doc = new Doctor();
const doctorId = doc.id; 
function EditTimeSlotsPopup({ isOpen, onClose, timeSlots, onRemove }) {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="popupedit">
            <div className="popup-inner1">
                <h2>Edit Time Slots</h2>
                <ul>
                    {timeSlots.map((slot, idx) => (
                        <li key={idx}>
                            {slot} 
                            <button onClick={() => onRemove(idx)} className="remove-btn">
                                🗑️ 
                            </button>
                        </li>
                    ))}
                </ul>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

const ScheduleTimings = () => {
    const navigate = useNavigate();

    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [timeSlotsToEdit, setTimeSlotsToEdit] = useState([]);
    
    const [slotDuration, setSlotDuration] = useState(null);
     const [breakDuration, setBreakDuration] = useState(null);
    const [timeSlots, setTimeSlots] = useState({});
    const [selectedDay, setSelectedDay] = useState('monday');
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

    useEffect(() => {
        const fetchTimings = async () => {
            try {
                const response = await axios.get(`/api/time_templates/doctor/${doctorId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                
                if (response.data.success) {
                    const formattedData = formatData(response.data.timeTemplates);
                    setTimeSlots(formattedData);
                    setSlotDuration(response.data.timeTemplates[0].slot_duration);
                    setBreakDuration(response.data.timeTemplates[0].break_duration);
                }
            } catch (error) {
                console.error('Error fetching the time slots:', error);
            }
        };

        fetchTimings();
    }, []);

    const formatData = (templates) => {
        const daysMapping = {
            1: 'monday',
            2: 'tuesday',
            3: 'wednesday',
            4: 'thursday',
            5: 'friday',
            6: 'saturday',
            0: 'sunday',
        };

        let formattedData = {};

        for (let template of templates) {
            const dayName = daysMapping[template.day_of_week];
            if (!formattedData[dayName]) {
                formattedData[dayName] = [];
            }

            formattedData[dayName].push(`${template.start_time} - ${template.end_time}`);
        }

        return formattedData;
    };

    const handleEditClick = () => {
        setTimeSlotsToEdit(timeSlots[selectedDay] || []);
        setIsEditPopupOpen(true);
    };
    
    const handleRemoveTimeSlot = (index) => {
        const newTimeSlots = [...timeSlotsToEdit];
        newTimeSlots.splice(index, 1);
        setTimeSlotsToEdit(newTimeSlots);
    };
    return (
        <div>
            {/* Header */}
            <Header />

            {/* Breadcrumb */}
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Schedule Timings</li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Schedule Timings Page</h2>
                        </div>
                    </div>
                </div>
            </div>
       
            {/* Main Content */}
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-5 col-lg-4 col-xl-3">
                            <Sidebar />
                        </div>
                        <div className="col-md-7 col-lg-8 col-xl-9">

                            <div className="d-flex align-items-center mb-3">
                                <label htmlFor="timeDuration" className="mr-3">
                                <FaBookMedical size={30} style={{ color: '#15558d' }} />    Time Slot Duration:
                                </label>
                                {slotDuration ? <span>{slotDuration} minutes</span> : <span> <FaExclamationTriangle size={20} style={{ color: '#15558d' }}/> </span>}
                            </div>
                            
                            <div className="d-flex align-items-center mb-3">
                                <label htmlFor="breakDuration" className="mr-3">
                                <FaCoffee size={30} style={{ color:'#15558d' }} />  Break Duration:
                                </label>
                                {breakDuration ? <span>{breakDuration} minutes</span> : <span> <FaExclamationTriangle size={20} style={{ color: '#15558d' }}/> </span>}
                            </div>
                            {!Object.keys(timeSlots).length && (
            <div className="centered-content">
                <span> </span>
                <button onClick={() => navigate('/AddHours') }  className="custom-button template-btn">
                   You Have no time slots , click here to add and organize your schedule
                </button>
                <div className="img-container">
                
            <img src={require('../../../assets/images/specialities/schedule.jpg')} alt="Schedule Image" className="img-responsive img-shadow"/>
            
        </div>
            </div>
        )}
                            <Nav variant="pills" activeKey={selectedDay} onSelect={setSelectedDay}>
                                {Object.keys(timeSlots).map(day => (
                                    <Nav.Item key={day}>
                                        <Nav.Link eventKey={day}>
                                            {day.charAt(0).toUpperCase() + day.slice(1)}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                               {Object.keys(timeSlots).length > 0 && (
    <a href="#" className="ml-auto" onClick={handleEditClick}>
        <FaEdit />
    </a>
)}
                            </Nav>
                            <div className="tag-container d-flex flex-wrap gap-3 mt-3">
                                {timeSlots[selectedDay]?.map((slot, idx) => (
                                    <button 
                                        key={idx} 
                                        className={`tag ${selectedTimeSlot === idx ? 'active' : ''}`}
                                        onClick={() => setSelectedTimeSlot(idx)}
                                    >
                                        {slot}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <EditTimeSlotsPopup 
    isOpen={isEditPopupOpen} 
    onClose={() => setIsEditPopupOpen(false)} 
    timeSlots={timeSlotsToEdit}
    onRemove={handleRemoveTimeSlot}
/>
            </div>
            <Footer />
        </div>
    );
};

export default ScheduleTimings;