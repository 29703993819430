import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './style.css';
import Sidebar from '../SideBar';
import '../../../App.css';
import Header from '../Header';
import Footer from '../../Footer';

const SocialMedia = () => {
    return(
      <div>
   
      <Header />
      

      
      <div class="breadcrumb-bar">
          <div class="container-fluid">
              <div class="row align-items-center">
                  <div class="col-md-12 col-12">
                      <nav aria-label="breadcrumb" class="page-breadcrumb">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><a href="/">Home</a></li>
                            
                              <li class="breadcrumb-item active" aria-current="page">Social Media</li>
                          </ol>
                      </nav>
                 
                      <h2 class="breadcrumb-title">Social Media Page</h2>
                  </div>
              </div>
          </div>
      </div>
   
      <div class="content">
          <div class="container-fluid">

              <div className="row">
                  {/* Sidebar Component */}
                  <div className="col-md-5 col-lg-4 col-xl-3 ">

                      <Sidebar />

                  </div>
                

               
                  <div className="col-md-7 col-lg-8 col-xl-9">
                  <Row> <h6 style={{color:'red'}}>This Page is Static. It doesn't send data to Backend</h6></Row>
                    
      <Container fluid className="socialmedia">
        <Row className="justify-content-md-right"  >
          <Col md={6} className="socialnames">
            <Form>
              <Form.Group>
                <Form.Label>Facebook URL</Form.Label>
                <Form.Control className="link" type='url' />

                <Form.Label>Twitter URL</Form.Label>
                <Form.Control className="link" type='url' />

                <Form.Label>Instagram URL</Form.Label>
                <Form.Control className="link" type='url' />

                <Form.Label>Pinterest URL</Form.Label>
                <Form.Control className="link" type='url' />

                <Form.Label>Linkedin URL</Form.Label>
                <Form.Control className="link" type='url' />

                <Form.Label>Youtube URL</Form.Label>
                <Form.Control className="link" type='url' />
              </Form.Group>

              <div className="text-area">
                <Button href="#" variant="primary" className="saveurl"  style={{backgroundColor:'#15558d'}}  type="submit">Save Changes</Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      
      </div>

      </div>

  </div>
</div>
{/* Footer */}
<Footer />
{/* Footer */}
</div>
    )
}

export default SocialMedia;