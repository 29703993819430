import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import Select from 'react-select';
import { FaEdit, FaTimes} from 'react-icons/fa';
import axios from  '../../../api/axios';
import Sidebar from '../SideBar';
import '../../../App.css';
import Header from '../Header';
import Footer from '../../Footer';
import './style.css'
import { getToken } from '../../../utils/auth';
import Doctor from '../../../utils/doctor_info';
import Row from 'react-bootstrap/Row';


const token = getToken;
const doc = new Doctor();

const doctorId = doc.id;

function Popup({ isOpen, onClose, children }) {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="popup">
            <div className="popup-inner">
                <button onClick={onClose} className="close-btn">Close  <FaTimes /></button>
                {children}
            </div>
        </div>
    );
}

const AddHours = () => {
    const navigate = useNavigate();
    
    const [slotDuration, setSlotDuration] = useState(null);
    const [breakDuration, setBreakDuration] = useState(null);
    
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [hasDesignedTemplate, setHasDesignedTemplate] = useState(false);
    const [isTemplateCreated, setIsTemplateCreated] = useState(false);
    const [daysTime, setDaysTime] = useState({
        Monday: { startTime: null, endTime: null },
        Tuesday: { startTime: null, endTime: null },
       Wednesday: { startTime: null, endTime: null },
        Thursday: { startTime: null, endTime: null },
        Friday: { startTime: null, endTime: null },
        Saturday: { startTime: null, endTime: null },
        Sunday: { startTime: null, endTime: null },
    });
    const [timeSlots, setTimeSlots] = useState({
        monday: [],
        tuesday: [],
    });

    

    const times = Array.from({ length: 24 }).map((_, index) => ({
        value: `${index < 10 ? '0' : ''}${index}:00`,
        label: `${index < 10 ? '0' : ''}${index}:00`,
    }));

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const dayToNumberMapping = {
        'Monday': 1,
        'Tuesday': 2,
        'Wednesday': 3,
        'Thursday': 4,
        'Friday': 5,
        'Saturday': 6,
        'Sunday': 0
    };
   
    const saveTimeTemplatesToDB = async () => {
        // Convert day name to number
       

        for (const [day, day_data] of Object.entries(daysTime)) {
            console.log(`Day: ${day}`);
            const dayNumber = dayToNumberMapping[day];
            const daySelectedTimes = daysTime[day];
           
           
            if(day_data['startTime']==null){
                    console.log("skipping")
                continue
               
            }

          

        // Construct the data you want to send to the server.
        const data = {
            breakDuration: breakDuration,
            dayOfWeek: dayToNumberMapping[day],
            endTime: daySelectedTimes.endTime,
            slotDuration: slotDuration,
            startTime: daySelectedTimes.startTime,
          
        };
      
        
        try {
            // Use Axios to send the POST request.
            const response = await axios.post(`/api/time_templates/doctor/${doctorId}/add`, data, {
                headers: {
                    'Authorization': `Bearer ${token} `,
                  
                }
            });
    
            // Check the response if needed.
            if (response.data.success) {
                console.log("Time template saved successfully:", response.data);
                // You might want to navigate to another page or show a success message
            } else {
                console.error("Error saving time template:", response.data.message);
                // Handle the error, show an error message or take another action
            }
        } catch (error) {
            console.error("An error occurred:", error);
            // Handle the error, show an error message or take another action
        }
    };
}
    
    if (!isTemplateCreated) {
        return (
            <div>
            <Header />

            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Schedule Timings</li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Schedule Timings Page</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
            <div className="container-fluid">
                <div className="row">
                    
                
                <div className="col-md-5 col-lg-4 col-xl-3">
                            <Sidebar />
                        </div>
                        {!isPopupOpen && (
                        
                        <div className="col-md-7 col-lg-8 col-xl-9 template-container">
                                <button onClick={() => setPopupOpen(true)} className="custom-button template-btn">
                                 Add New Template
                                </button>
                                <div className='img-container'>
                               
                               <img className="img-responsive img-shadow" src={require('../../../../src/assets/images/login-banner.png')} alt="Description" />
                           </div>
                            </div>
                        )}
                            </div>
                         </div>  
                <Footer />
                </div>
                
                
               
<Popup isOpen={isPopupOpen} onClose={() => setPopupOpen(false)}>
    <h2>Add Business Hours</h2>
    <form onSubmit={async (e) => {
        e.preventDefault();
        await saveTimeTemplatesToDB();
        navigate('/ScheduleTimings');
     
    }}>
             <div className="time-slot-edit-container">
              
        <div className="time-slot-edit-item" style={{width:'50%'}}>
            <label>Slot Duration:</label>
            <Select 
                  options={[
                    { value: '15', label: '15 minutes' },
                    { value: '20', label: '20 minutes' },
                    { value: '25', label: '25 minutes' },
                    { value: '30', label: '30 minutes' },
                    { value: '35', label: '35 minutes' },
                    { value: '40', label: '40 minutes' },
                    { value: '45', label: '45 minutes' },
                    { value: '50', label: '50 minutes' },
                    { value: '55', label: '55 minutes' },
                    { value: '60', label: '60 minutes' },
                ]}
                 onChange={selected => {
                     setSlotDuration(selected.value);
                 }}
            />
        </div>

        <div className="time-slot-edit-item"  style={{width:'50%'}}>
            <label>Break Duration:</label>
            <Select 
                options={[
                    { value: '1', label: '1 minutes' },
                    { value: '2', label: '2 minutes' },
                    { value: '3', label: '3 minutes' },
                    { value: '4', label: '4 minutes' },
                    { value: '5', label: '5 minutes' },
                    { value: '6', label: '6 minutes' },
                    { value: '7', label: '7 minutes' },
                    { value: '8', label: '8 minutes' },
                    { value: '9', label: '9 minutes' },
                    { value: '10', label: '10 minutes' },
                ]}
               
                 onChange={selected => {
                     setBreakDuration(selected.value);
                 }}
            />
        </div>

        {days.map(day => (
            <div key={day}>
                <h3>{day}</h3>
              
                <div className="time-slot-edit-item">
                    
                    <label>Start Time:</label>
                    <Select 
                        options={times}
                        onChange={selected => {
                            setDaysTime(prevState => ({
                                ...prevState,
                                [day]: { ...prevState[day], startTime: selected.value }
                            }));
                        }}
                    />
                </div>

                <div className="time-slot-edit-item">
                    <label>End Time:</label>
                    <Select 
                        options={times}
                        onChange={selected => {
                            setDaysTime(prevState => ({
                                ...prevState,
                                [day]: { ...prevState[day], endTime: selected.value }
                            }));
                        }}
                    />
                </div>
               
            </div>
        ))}
        
        <button type="submit" className="save-btn" >Save Changes</button>
        </div>
    </form>
</Popup>

            </div>
        );
    }

    if (!hasDesignedTemplate) {
        return (
            <div>
                <Header />
                <div className="centered-content">
                    {/* Prompt user to design template or other specific action */}
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <div>
            <Header />

            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Schedule Timings</li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Schedule Timings Page</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-5 col-lg-4 col-xl-3">
                            <Sidebar />
                        </div>
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <h2>Time Slots</h2>
         
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AddHours;