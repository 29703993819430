import * as React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';


export default function Review(props) {
  //const [code, setCode] = React.useState(props);
 

  function handleChange(event) {
    event.preventDefault();
    
    if (event.target.id === 'code') {
      console.log(event.target.value);
      //setCode(event.target.value);
      props.updateOTPCode(event.target.value);
    }
  }
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Verify Your Email
      </Typography>
      <Grid container spacing={3}>
        
      <Grid item xs={12} md={6}>
      <input
   type="text"
   required
   id="code"
   name="code"
   onChange={handleChange}
   value={props.code}
/>
          {/*<TextField
            required
            id="code"
            label="Code"
            fullWidth
            variant="standard"
            onChange={handleChange}
            value={code}
          />*/}
        </Grid></Grid>
    </React.Fragment>
  );
}